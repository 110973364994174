.wrapper {
  @apply flex flex-col relative;
}

.content {
  @apply relative bg-no-repeat bg-cover w-full flex flex-col items-center;
  background-position: 50% 35%;
  height: calc(89rem * var(--ratio));
  @apply lg:h-[89rem];
}

.titleTextWrapper {
  @apply flex items-center justify-center lg:justify-start w-full h-full -mt-24 sm:mt-0;
  max-width: var(--max-width);
}

.titleText {
  @apply flex flex-col p-4 bg-black/40 text-white;
  @apply lg:ml-10 lg:bg-transparent lg:-mt-52 lg:text-black;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: calc(4rem * var(--ratio));
  line-height: 131.19%;
  text-align: center;
  letter-spacing: -0.029em;
  @apply lg:text-[4rem];
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: calc(3rem * var(--ratio));
  line-height: 131.19%;
  text-align: center;
  letter-spacing: -0.029em;
  @apply lg:text-[3rem];
}

.detailWrapper {
  @apply relative flex flex-col items-center text-center py-24 px-4 bg-white;
}

.detailTitle {
  font-style: normal;
  font-weight: 500;
  font-size: calc(4rem * var(--ratio));
  line-height: 108.5%;
  text-align: center;
  letter-spacing: -0.009em;
  color: #222222;
  @apply lg:text-[4rem];
}

.detailText {
  font-style: normal;
  font-weight: 300;
  font-size: calc(2rem * var(--ratio));
  line-height: 131.19%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #0e0e0e;
  opacity: 0.9;
  margin-top: 2.5rem;
  @apply lg:text-[2rem];
}

.detailText2 {
  font-style: normal;
  font-weight: 300;
  font-size: calc(4rem * var(--ratio));
  line-height: 131.19%;
  text-align: center;
  letter-spacing: -0.029em;
  color: #000000;
  margin-top: 2.5rem;
  @apply lg:text-[4rem];
}

.detailText3 {
  font-style: normal;
  font-weight: normal;
  font-size: calc(2.5rem * var(--ratio));
  line-height: 131.19%;
  text-align: center;
  letter-spacing: -0.004em;
  color: #000000;
  @apply lg:text-[2.5rem] lg:mt-10;
}

.gradientTextWrapper {
  @apply flex flex-col px-1;
  margin-top: 2.5rem;
}

.detailText4 {
  font-style: normal;
  font-weight: 300;
  font-size: calc(3.5rem * var(--ratio));
  line-height: 108.5%;
  text-align: center;
  letter-spacing: -0.009em;
  color: #000000;
  @apply lg:text-[3.5rem];
}

.detailText5 {
  font-style: normal;
  font-weight: 500;
  font-size: calc(2rem * var(--ratio));
  line-height: 131.19%;
  letter-spacing: 0.08em;
  color: #000000;
  opacity: 0.9;
  margin-top: 1rem;
  @apply lg:text-[2rem];
}

.detailText6 {
  font-style: normal;
  font-weight: normal;
  font-size: calc(2.25rem * var(--ratio));
  line-height: 131.19%;
  text-align: center;
  letter-spacing: 0.08em;
  color: #0066cc;
  opacity: 0.9;
  margin-top: 2rem;
  @apply cursor-pointer lg:text-[2.25rem];
}

.detailGradient {
  background-color: #f3ec78;
  background-image: linear-gradient(to right, #ff3232, #bb00da, #ef8bff);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.leafImg {
  height: calc(25rem * var(--ratio));
  @apply lg:h-[25rem] lg:mt-10;
}
