.footerWrapper {
  @apply flex justify-center items-center;
}

.footer {
  @apply flex flex-col items-center;
}

.footerContent {
  @apply text-white w-full flex justify-center flex-col-reverse py-12 px-4;
  @apply sm:flex-row sm:space-y-0 sm:space-x-24 sm:py-24;
  max-width: var(--max-width);
}

.contactUsWrapper {
  @apply mt-12 sm:mt-0;
}

.footerTitle {
  @apply text-xl font-semibold;
}

.contactUs {
  @apply flex flex-col space-y-2 font-light;
}
.navLinksWrapper {
  @apply flex flex-col space-y-8;
}

.navLink {
  @apply flex flex-col space-y-2 font-light;
}

.navitasText {
  @apply text-gray-200 py-2;
}
