:root {
  --nost-ratio: 0.1;
}

@media (min-width: 300px) {
  :root {
    --nost-ratio: 0.2;
  }
}

@media (min-width: 400px) {
  :root {
    --nost-ratio: 0.3;
  }
}

@media (min-width: 600px) {
  :root {
    --nost-ratio: 0.4;
  }
}

@media (min-width: 700px) {
  :root {
    --nost-ratio: 0.5;
  }
}

@media (min-width: 800px) {
  :root {
    --nost-ratio: 0.6;
  }
}

@media (min-width: 900px) {
  :root {
    --nost-ratio: 0.7;
  }
}

@media (min-width: 1100px) {
  :root {
    --nost-ratio: 0.8;
  }
}

@media (min-width: 1300) {
  :root {
    --nost-ratio: 1;
  }
}

.wrapper {
  @apply flex flex-col justify-start items-start bg-white py-12 lg:py-24 overflow-auto;
}

.content {
  @apply relative bg-no-repeat bg-contain w-full flex flex-col items-center mx-auto;
  background-position: 50% 50%;
  height: calc(65.25rem * var(--nost-ratio));
  width: calc(98.5rem * var(--nost-ratio));
  @apply lg:h-[65.25rem] lg:w-[98.5rem];
}

.titleText {
  @apply flex flex-col items-center text-black;
  margin-top: calc(8rem * var(--nost-ratio));
  @apply lg:mt-32;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: calc(4.5rem * var(--nost-ratio));
  line-height: 131.19%;
  letter-spacing: 0.009em;
  @apply lg:text-[4.5rem];
}

.text {
  font-style: italic;
  font-weight: 500;
  font-size: calc(2rem * var(--nost-ratio));
  line-height: 132.69%;
  letter-spacing: 0.175em;
  @apply lg:text-[2rem];
}

.overlayTextWrapper {
  @apply flex-1 w-full flex flex-row items-end;
}

.otLeft {
  width: calc(30.625rem * var(--nost-ratio));
  height: calc(16.5rem * var(--nost-ratio));
  @apply lg:w-[30.625rem] lg:h-[16.5rem];
}

.otCenter {
  width: calc(36.25rem * var(--nost-ratio));
  height: calc(16.5rem * var(--nost-ratio));
  @apply lg:w-[36.25rem] lg:h-[16.5rem];
}

.otRight {
  width: calc(26.25rem * var(--nost-ratio));
  height: calc(16.5rem * var(--nost-ratio));
  @apply lg:w-[26.25rem] lg:h-[16.5rem];
}

.footerWrapper {
  background-color: #fafafa;
  @apply w-full h-full flex items-start justify-center py-6 lg:py-12 px-4;
}

.footerText {
  font-style: italic;
  font-weight: 300;
  font-size: calc(1.75rem * var(--nost-ratio));
  line-height: 131.19%;
  text-align: center;
  color: #626262;
  opacity: 0.9;
  max-width: var(--max-width);
  @apply lg:text-[1.75rem];
}

.shape1 {
  float: right;
  width: 100%;
  height: 100%;
  shape-outside: polygon(
    100% 0,
    100% 100%,
    97% 100%,
    96% 80%,
    95.3% 40%,
    97% 0
  );
}

.shape2 {
  float: left;
  width: 50%;
  height: 100%;
  shape-outside: polygon(
    0 0,
    0 100%,
    13% 100%,
    10.5% 80%,
    9% 50%,
    9.5% 20%,
    11.5% 0
  );
}

.shape3 {
  float: right;
  width: 50%;
  height: 100%;
  shape-outside: polygon(
    100% 0,
    100% 100%,
    92% 100%,
    93.5% 50%,
    92% 25%,
    89.5% 0
  );
}

.shape4 {
  float: left;
  width: 100%;
  height: 100%;
  shape-outside: polygon(
    0 0,
    0 100%,
    9.5% 100%,
    10% 80%,
    10.3% 60%,
    9.5% 25%,
    8% 0
  );
}

.shape1,
.shape2,
.shape3,
.shape4 {
  shape-margin: calc(0.625rem * var(--nost-ratio));
}

@media (min-width: 1024px) {
  .shape1,
  .shape2,
  .shape3,
  .shape4 {
    shape-margin: 0.625rem;
  }
}

.overlayText {
  font-style: normal;
  font-weight: normal;
  font-size: calc(1.625rem * var(--nost-ratio));
  line-height: 131.19%;
  letter-spacing: 0.06em;
  color: #000000;
  opacity: 0.9;
  @apply lg:text-[1.625rem] md:h-0;
}

.centerIfNotJustify {
  text-align: center;
  text-align-last: justify;
}
