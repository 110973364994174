.indicator {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  top: calc(2.5rem * var(--sm-ratio));
  @apply w-12 h-12 absolute lg:top-10 left-0 right-0 mx-auto text-white;
  @apply flex justify-center items-center rounded-full text-xl cursor-pointer;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-10px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-10px);
  }
}

.remainingText {
  font-style: italic;
  font-weight: 500;
  font-size: calc(2rem * var(--sm-ratio));
  line-height: 132.69%;
  letter-spacing: 0.175em;
  color: #000000;
  @apply bg-white px-4 text-center break-words;
  padding-top: calc(5rem * var(--sm-ratio));
  padding-bottom: calc(5rem * var(--sm-ratio));
  @apply lg:py-20 lg:text-[2rem];
}

.storyWrapper {
  padding-top: calc(10rem * var(--ratio));
  background-repeat: no-repeat;
  background-size: 100%;
  @apply flex-1 text-white text-center relative lg:pt-40 overflow-auto;
}

.story {
  @apply mx-auto flex flex-col items-center;
  width: calc(100rem * var(--sm-ratio));
  @apply lg:w-[100rem];
}

.storyTitle {
  font-style: normal;
  font-weight: 600;
  font-size: calc(5.125rem * var(--sm-ratio));
  line-height: 131.19%;
  letter-spacing: 0.002em;
  @apply lg:text-[5.125rem];
}

.storyText {
  font-style: normal;
  font-weight: normal;
  font-size: calc(1.75rem * var(--sm-ratio));
  line-height: 131.19%;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  @apply break-words;
  @apply lg:text-[1.75rem];
}

.lampContentWrapper {
  @apply w-full flex flex-col justify-start items-center mx-auto;
  width: calc(100rem * var(--sm-ratio));
  height: calc(111.25rem * var(--sm-ratio));
  @apply lg:w-[100rem];
  @apply lg:h-[111.25rem];
}

.thunderImg {
  @apply animate-pulse;
  margin-top: calc(3rem * var(--sm-ratio));
  height: calc(11.375rem * var(--sm-ratio));
  @apply lg:mt-12 lg:h-[11.375rem];
}

.lampBottomText {
  font-style: normal;
  font-weight: normal;
  font-size: calc(5rem * var(--sm-ratio));
  line-height: 118.69%;
  @apply p-8 lg:p-12 lg:text-[5rem];
}

.lampContent {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @apply flex flex-row;
  height: calc(78.75rem * var(--sm-ratio));
  @apply lg:h-[78.75rem];
}

.lampText {
  font-style: normal;
  font-weight: normal;
  font-size: calc(1.6rem * var(--sm-ratio));
  @apply h-8 flex flex-row justify-between;
  @apply lg:text-[1.6rem];
}

.lampParagraph {
  @apply w-full flex flex-col justify-between items-end box-border;
  height: calc(51.875rem * var(--sm-ratio));
  @apply lg:h-[51.875rem];
}

.lampParagraphRight {
  @apply lampParagraph;
  @apply items-start;
  height: calc(51.875rem * var(--sm-ratio));
  @apply lg:h-[51.875rem];
}
