.headerWrapper {
  @apply lg:hidden relative;
}

.header {
  @apply flex justify-between h-full;
}

.burgerMenu {
  @apply text-white flex justify-center items-center;
}

.menu {
  @apply bg-black w-screen text-black z-50 fixed top-12;
  max-height: 0;
  height: 100vh;
  overflow: hidden;
  transition: max-height 0.25s ease-out;
}

.menuOpen {
  @apply block;
  max-height: 100vh;
  transition: max-height 0.45s ease-in;
}

.navLinks {
  @apply p-4 flex flex-col items-start text-white text-sm font-bold;
}

.headerItemWrapper {
  @apply border-b-4 border-transparent py-2 hover:border-white cursor-pointer;
}

.headerItemWrapper span {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #f8f9f7;
  @apply break-words;
}

.popoverItemWrapper {
  @apply space-x-2 py-2 flex flex-row items-center cursor-pointer text-gray-400 hover:text-white;
}

.popoverItemWrapper:hover svg {
  @apply text-blue-500;
}
