.headerWrapper {
  @apply hidden lg:block;
}

.header {
  @apply h-full flex flex-row items-center mx-auto;
  max-width: var(--max-width);
}

.headerItemWrapper {
  @apply border-b-4 border-transparent py-2 hover:border-white cursor-pointer;
}

.headerItemWrapper span {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #f8f9f7;
}

.popoverItemWrapper {
  @apply space-x-2 py-2 flex flex-row items-center cursor-pointer text-gray-400 hover:text-white;
}

.popoverItemWrapper:hover svg {
  @apply text-blue-500;
}

.navLinksWrapper {
  @apply flex-1 flex justify-center lg:justify-end px-2;
}

.navLinks {
  @apply space-x-16 flex text-white text-sm font-bold;
}
