.contentWrapper {
  @apply py-0 lg:py-48;
}

.content {
  @apply relative bg-no-repeat bg-cover;
  @apply w-full flex flex-col items-center justify-center lg:justify-start px-4;
  @apply text-white text-center;
  background-position: 50% 50%;
  height: 48.875rem;
}

.titleWrapper {
  @apply flex flex-col;
}

.title1 {
  font-style: normal;
  font-weight: 500;
  font-size: calc(7rem * var(--ratio));
  line-height: 98.19%;
  letter-spacing: -0.013em;
  @apply lg:-mt-12 my-auto lg:text-[8rem];
}

.title2 {
  font-style: normal;
  font-weight: 500;
  font-size: calc(8rem * var(--ratio));
  line-height: 80%;
  letter-spacing: -0.013em;
  color: #8f84c7;
  @apply lg:text-[10rem];
}

.text {
  font-style: italic;
  font-weight: 400;
  font-size: calc(1.75rem * var(--ratio));
  line-height: 140%;
  opacity: 0.85;
  text-shadow: 0px 4px 4px #000000;
  margin-top: 4.5rem;
  @apply lg:text-[1.75rem];
}
