.wrapper {
  @apply relative w-full flex flex-col items-center justify-start pt-24;
  background-color: #f5f5f5;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: calc(4rem * var(--ratio));
  line-height: 108.5%;
  text-align: center;
  letter-spacing: -0.009em;
  @apply lg:text-[4rem];
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: calc(2rem * var(--ratio));
  line-height: 131.19%;
  text-align: center;
  letter-spacing: 0.08em;
  opacity: 0.9;
  margin-top: 2rem;
  @apply flex flex-col px-4 lg:text-[2rem];
}

.text span {
  margin-top: 2rem;
}

.textPurple {
  color: #653766;
}

.textBlue {
  @apply cursor-pointer;
  color: #0066cc;
}

.pencilImgWrapper {
  @apply h-16 lg:h-24 flex items-center justify-center overflow-hidden;
  @apply mt-12 lg:mt-16 mb-24 lg:mb-32;
}

.pencilImg {
  filter: drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.25));
  width: calc(26rem * var(--ratio));
  height: calc(26rem * var(--ratio));
  @apply lg:w-[26rem] lg:h-[26rem];
}

.laptopImgWrapper {
  @apply bg-no-repeat bg-contain w-full;
  background-position: 50% 50%;
  height: calc(42.625rem * var(--ratio));
  @apply lg:h-[42.625rem];
}

.laptopTextWrapper {
  @apply flex flex-col text-center p-12 bg-black/50 shadow-lg;
  @apply lg:bg-transparent lg:shadow-none;
  color: #eeeeee;
}

.laptopTextTitle {
  font-style: normal;
  font-weight: 600;
  font-size: calc(5.25rem * var(--ratio));
  line-height: 131.19%;
  letter-spacing: 0.003em;
  @apply lg:text-[5.25rem];
}

.laptopText {
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 131.19%;
  letter-spacing: 0.08em;
  opacity: 0.9;
}

.commercialText {
  font-style: normal;
  font-weight: 300;
  font-size: calc(2.5rem * var(--ratio));
  line-height: 131.19%;
  text-align: center;
  letter-spacing: -0.035em;
  color: #2c2c2c;
  @apply mb-12 mt-4 lg:mt-16 lg:text-[2.5rem];
}
