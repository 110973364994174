.contentWrapper {
  @apply w-full flex flex-col items-center justify-center lg:justify-start relative;
  @apply bg-no-repeat bg-cover lg:h-[46.5rem];
  background-position: 100% 45%;
  min-height: 31.25rem;
}

@media (min-width: 640px) {
  .contentWrapper {
    background-position: 50% 45%;
  }
}

.titleWrapper {
  @apply flex flex-col w-full p-0 lg:p-12 pt-0 justify-start mt-24;
  min-height: 25rem;
  max-width: 112.5rem;
}

.title {
  @apply flex flex-col text-white break-words bg-black/50 p-4 items-center;
  @apply lg:bg-transparent lg:items-start;
}

.thinText {
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 130%;
  letter-spacing: 0.02em;
  @apply lg:text-[2.5rem];
}

.boldText {
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  line-height: 120%;
  letter-spacing: -0.05em;
  @apply lg:text-[5rem];
}

.ls0085 {
  letter-spacing: 0.085em;
}

.buttonsWrapper {
  @apply mt-16 flex flex-col justify-center items-center space-y-6;
  @apply lg:flex-row lg:mt-32 lg:justify-start lg:space-y-0 lg:space-x-12;
}

.buttonWrapper {
  @apply flex flex-row cursor-pointer;
}

.buttonWrapper:hover {
  filter: brightness(85%);
}

.button {
  width: 10rem;
  height: 3rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 116%;
  letter-spacing: 0.095em;
  background-color: #1d80d3;
  @apply text-white bg-blue-500 flex justify-center items-center;
  @apply lg:text-[1.4rem] lg:w-[18.75rem] lg:h-[5rem];
}

.buttonLogo {
  width: 3rem;
  background-color: #1978c8;
  font-size: 1.875rem;
  @apply text-white flex justify-center items-center;
  @apply lg:w-[5rem];
}
