:root {
  --ratio: 0.6;
  --sm-ratio: 0.3;
  --max-width: 100rem;
}

body {
  @apply bg-black;
  font-family: "Roboto";
  min-width: 12rem;
}

main {
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.logo {
  max-width: 276px;
  max-height: 60px;
  @apply w-auto h-full py-3;
}

.ant-layout-header {
  @apply bg-black/90 shadow-2xl z-20 px-4 lg:px-40 h-12 lg:h-16 w-full fixed backdrop-blur-md;
  backdrop-filter: blur(2em);
  border-top-width: 0.36px;
  border-bottom-width: 0.36px;
  border-color: #e4e4e466;
  box-sizing: border-box;
}

@-moz-document url-prefix() {
  .ant-layout-header {
    @apply bg-black;
  }
}

.ant-layout-content {
  @apply bg-black;
}

.ant-layout {
  @apply bg-black min-h-screen;
}

.ant-layout-footer {
  @apply bg-gray-900 text-white;
}

.ant-popover-arrow-content {
  @apply bg-transparent fixed;
}

.ant-popover-inner {
  @apply bg-black;
}

.ant-popover-inner-content {
  font-style: normal;
  font-weight: 300;
  font-size: 14.3218px;
  line-height: 17px;
  letter-spacing: 0.175em;
  @apply text-white;
}

.horizontal-separator {
  @apply bg-white w-full absolute bottom-0;
  height: 6px;
}

.ant-layout-footer {
  @apply bg-black;
}
