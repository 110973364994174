.wrapper {
  @apply flex flex-col relative;
}

.imageHeaderTop {
  @apply h-12 lg:h-16;
  background-image: linear-gradient(
    to right,
    #47538f 0.01%,
    #434e87 24.87%,
    #495087 35.81%,
    #3f4b81 43.1%,
    #3c487c 62.37%,
    #4a4979 71.74%,
    #384373 77.47%,
    #35406e 99.8%
  );
}

.titleWrapper {
  @apply relative bg-no-repeat bg-cover w-full flex flex-col items-center justify-start text-white;
  @apply px-2 text-center;
  background-position: 50% 50%;
  height: calc(74.625rem * var(--ratio));
  @apply lg:h-[74.625rem];
}

.text1 {
  font-style: normal;
  font-weight: 500;
  font-size: calc(2.18rem * var(--ratio));
  line-height: 131.19%;
  letter-spacing: -0.035em;
  margin-top: 1.31rem;
  @apply lg:text-[2.18rem];
}

.text2 {
  font-style: normal;
  font-weight: 500;
  font-size: calc(5.25rem * var(--ratio));
  line-height: 100%;
  letter-spacing: 0.003em;
  @apply lg:text-[5.25rem];
}

.text3 {
  font-style: normal;
  font-weight: 300;
  font-size: calc(2rem * var(--ratio));
  line-height: 100%;
  letter-spacing: 0.08em;
  opacity: 0.9;
  margin-top: 1rem;
  @apply lg:text-[2rem];
}
