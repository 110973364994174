.wrapper {
  @apply flex flex-col relative;
}

.content {
  @apply relative bg-no-repeat bg-cover w-full flex flex-col items-center;
  background-position: 70% bottom;
  height: calc(56.25rem * var(--ratio));
  @apply lg:h-[56.25rem];
}

@media (min-width: 1024px) {
  .content {
    background-position: 30% 30%;
  }
}

.titleTextWrapper {
  @apply flex items-center justify-start w-full h-full mt-32 lg:mt-0;
  max-width: var(--max-width);
}

.titleText {
  @apply flex flex-col text-center px-8 text-white;
}

.title1 {
  font-style: normal;
  font-weight: 600;
  font-size: calc(2rem * var(--ratio));
  line-height: 131.19%;
  letter-spacing: -0.035em;
  @apply lg:text-[2rem];
}

.title2 {
  font-style: normal;
  font-weight: 600;
  font-size: calc(5.25rem * var(--ratio));
  line-height: 100%;
  letter-spacing: 0.003em;
  @apply lg:text-[5.25rem];
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 100%;
  letter-spacing: 0.009em;
  color: #d38573;
  @apply animate-pulse lg:text-[2.25rem] lg:mt-5;
}

.gradient {
  background-color: #f3ec78;
  background-image: linear-gradient(
    45deg,
    #9f7670,
    #cb6a6a,
    #9f0707,
    #a6539e,
    #510876,
    #bb00da,
    #7f0bb0,
    #9e26c5
  );
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.detailWrapper {
  @apply bg-white flex flex-col items-center;
}

.detailContent {
  @apply flex flex-col items-center text-center py-12 lg:py-24 px-4;
  max-width: var(--max-width);
}

.detailTitle {
  font-style: normal;
  font-weight: 800;
  font-size: calc(6.25rem * var(--ratio));
  line-height: 101%;
  text-align: center;
  letter-spacing: -0.005em;
  color: #000000;
  @apply lg:text-[6.25rem];
}

.detailText {
  font-style: normal;
  font-weight: 600;
  font-size: calc(1.75rem * var(--ratio));
  line-height: 117%;
  text-align: center;
  color: #86868b;
  margin-top: 3.75rem;
  @apply lg:text-[1.75rem];
}

.fpContentWrapper {
  @apply bg-no-repeat bg-contain w-full flex flex-col items-start relative;
  margin-top: calc(6.25rem * var(--ratio));
  max-width: var(--max-width);
  @apply lg:mt-[6.25rem];
}

.fpImg {
  @apply z-0 w-full;
}

.fpContent {
  @apply flex justify-center sm:justify-start space-y-8 z-10 absolute top-0;
  padding-top: 10%;
  padding-left: 10%;
  max-width: var(--max-width);
}

.fpTextWrapper {
  @apply flex flex-col text-white;
}

.fpText1 {
  font-style: normal;
  font-weight: 600;
  line-height: 131.19%;
  letter-spacing: -0.035em;
  @apply text-[.9rem] sm:text-[1.4rem] md:text-[1.7rem] lg:text-[2rem];
}

.fpText2 {
  font-style: normal;
  font-weight: 600;
  font-size: calc(5.25rem * var(--ratio));
  line-height: 131.19%;
  letter-spacing: 0.003em;
  @apply text-[2.36rem] sm:text-[3.675rem] md:text-[4.46rem] lg:text-[5.25rem];
}

.fpText3 {
  font-style: normal;
  font-weight: 800;
  font-size: calc(6.25rem * var(--ratio));
  line-height: 101%;
  text-align: center;
  letter-spacing: -0.005em;
  color: #484848;
  @apply lg:text-[6.25rem] mt-8 lg:mt-20;
}

.moreWrapper {
  @apply flex flex-col items-center text-center py-12 lg:py-24;
  background-color: #f5f5f7;
}

.moreContent {
  @apply flex flex-col justify-start text-left w-full h-full space-y-8 px-4;
  max-width: var(--max-width);
}

.moreContent span {
  font-style: normal;
  font-weight: bold;
  font-size: calc(3rem * var(--ratio));
  line-height: 117%;
  letter-spacing: -0.007em;
  color: #86868b;
  @apply lg:text-[3rem];
}

.blackText {
  @apply text-black !important;
}

.moreWrapper2 {
  @apply flex flex-col relative items-center bg-white px-4;
}

.moreContent2 {
  @apply flex flex-col justify-start text-left w-full h-full space-y-8 py-12;
  max-width: var(--max-width);
}

.moreContent2Text {
  font-style: italic;
  font-weight: 300;
  font-size: calc(2rem * var(--ratio));
  line-height: 131.19%;
  text-align: center;
  letter-spacing: -0.005em;
  color: #000000;
  @apply lg:text-[2rem];
}
